import "./projects.css";


export const ProjectsPage = () => {

    const ProjCards = ({ img, text, href, href2, skill, description }) => {
        return (
            <div className="cards">
                <img src={img} alt="image" className="card-preview-img"/>
                <div className="cards-info">
                    <div className="title-and-links">
                        <span className="project-title">
                            {text}
                        </span>
                        <div className="project-links">
                            <a href={href}>
                                <i className="fa fa-external-link"></i>
                            </a>
                            <a href={href2}>
                                <i className="fa fa-github"></i>
                            </a>
                        </div>
                    </div>
                    <div className="project-skills">
                        <span>{skill}</span>
                        <span>CSS</span>
                    </div>
                    <p className="project-description">
                        {description}
                    </p>
                </div>
            </div>
        )
    };

    return (
        <div className="projects mt-3" id="select-projects">
            <h2>Select Projects</h2>

            <p className="section-description">
                Here are some personal projects I have worked on.<br />
                    You can find more on <a class="hyperlink"
                        href="https://github.com/michuyim" target="_blank" rel="noreferrer noopener">GitHub</a>.
            </p>


            <div className="project-cards-container">
                <ProjCards img="https://3dpepe.pages.dev/images/p3d-5.png" text="3dPepe" href="https://3dpepe.pages.dev/" href2="https://github.com/michuyim/3dpepe.git" skill="React JS" description="Landing page SPA for a new crypto currency Pepe 3d. I worked on this project for a friend" />
                
                <ProjCards img="https://3dpepe.pages.dev/images/p3d-5.png" text="3dPepe" href="https://3dpepe.pages.dev/" href2="https://github.com/michuyim/3dpepe.git" skill="React JS" description="Landing page SPA for a new crypto currency Pepe 3d. I worked on this project for a friend" />

                <ProjCards img="https://3dpepe.pages.dev/images/p3d-5.png" text="3dPepe" href="https://3dpepe.pages.dev/" href2="https://github.com/michuyim/3dpepe.git" skill="React JS" description="Landing page SPA for a new crypto currency Pepe 3d. I worked on this project for a friend" /> 
                 
                <ProjCards img="https://3dpepe.pages.dev/images/p3d-5.png" text="3dPepe" href="https://3dpepe.pages.dev/" href2="https://github.com/michuyim/3dpepe.git" skill="React JS" description="Landing page SPA for a new crypto currency Pepe 3d. I worked on this project for a friend" />
            </div>
        </div>
    )
}