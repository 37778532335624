import React, { useEffect, useRef, useState } from 'react';
import './photos.css';  // If you prefer to keep styles in a separate CSS file

const images = [
  "https://3dpepe.pages.dev/images/p3d-5.png",
  "https://3dpepe.pages.dev/images/p3d-5.png",
  "https://3dpepe.pages.dev/images/p3d-5.png",
  "https://3dpepe.pages.dev/images/p3d-5.png",
  "https://3dpepe.pages.dev/images/p3d-5.png",
  "https://3dpepe.pages.dev/images/p3d-5.png",
  "https://3dpepe.pages.dev/images/p3d-5.png",
  "https://3dpepe.pages.dev/images/p3d-5.png",
  "https://3dpepe.pages.dev/images/p3d-5.png"
];

const Gallery = () => {
  const slideGallery = useRef(null);
  const [highlightedIndex, setHighlightedIndex] = useState(0);

  const highlightThumbnail = () => {
    const index = Math.floor(slideGallery.current.scrollLeft / 1080);
    setHighlightedIndex(index);
  };

  const scrollToElement = (index) => {
    slideGallery.current.scrollTo({
      left: index * 1080,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const gallery = slideGallery.current;
    gallery.addEventListener('scroll', highlightThumbnail);

    return () => {
      gallery.removeEventListener('scroll', highlightThumbnail);
    };
  }, []);

  return (
    <div className='main-container '>
    <div className="gallery-container section">
        <div className='page-title mt-5 '>
            <h2>GALLERY</h2>
            </div>
      <div className="thumbnails">
        {images.map((_, index) => (
          <div
            key={index}
            data-id={index}
            className={`thumbnail ${highlightedIndex === index ? 'highlighted' : ''}`}
            onClick={() => scrollToElement(index)}
          ></div>
        ))}
      </div>
      <div className="slides" ref={slideGallery}>
        {images.map((src, index) => (
          <div key={index}>
            <img src={src} alt={`Slide ${index}`} />
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Gallery;