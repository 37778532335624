import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import "./navbar.css";

const Link = ({ text, href, exact = false }) => {
  return (
    <li className="nav-item p-1 m-3">
      <NavLink exact={exact} className="p-2 mb-2  link"  to={href} activeClassName="active">
        {text}
      </NavLink>
    </li>
  );
};

export const Navbar = () => {
    const [show, setShow] = useState(false);
    const handleToggle = () => setShow(!show);

  return (
    <>
    <nav className="navbar nav-container m-0 fixed-top navbar-expand-lg bg-danger p-2 align-content-between shadow-sm mb-3">
      <div className="container-fluid">
        <NavLink className="navbar-brand mb-0 text-white bg-transparent fw-bolder mx-3" style={{ fontFamily: 'monospace' }} to="/">
         michuyim_
        </NavLink>

          <button className="nav-toggle" onClick={handleToggle}>
                        &#9776;
                    </button>
                    </div>

        <div className={`nav-offcanvas text-white bg-dark ${show ? 'nav-show' : 'nav-hide'}`}>
            <button className="nav-close" onClick={handleToggle}>&times;</button>
            <ul className=" mb-2 flex-column p-2 justify-content-center">
              <Link href="/" text="Home" exact={true} />
              <Link href="/about" text="About" />
              <Link href="/projects" text="Projects" />
              <Link href="/photos" text="Photos" />
            </ul>
        </div>

        
          <ul className="navbar-nav d-lg-flex bg-transparent d-none nav-links-large-screen">
            <Link href="/" text="Home" exact={true} />
            <Link href="/about" text="About" />
            <Link href="/projects" text="Projects" />
            <Link href="/photos" text="Photos" />
          </ul>
    </nav>
      {show && <div className="overlay" onClick={handleToggle}></div>}
      </>
  );
};