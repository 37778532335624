import "./home.css";

const Socials = ({ href, icon, text }) => {
    return (
        <li className="social-link">
            <a href={href} target="_blank" ariaLabel={text}>
                <i className={icon}></i>
            </a>
        </li>
    )
}

export const HomeSection = () => {
    return (
       <div className="main-container">
            <div id="home">
                <div className="landing-page-details">
                    <h1 className="small-title">hello,</h1>
                    <h2 className="big-title">my name is <span className="my-name-blue">michuyim.</span></h2>
                    <h3 className="medium-title">React-Javascript Web Developer</h3>
                </div>

                <div className="art-me">
                    <img src="/img/art-one.png" className="shadow-sm" alt="photo of an animated version of me. snapchat avartar sticker" />
                </div>

                <div className="landing-page-socials">
                    <ul>
                        <Socials href="https://instagram.com/michuyim" icon="fa fa-instagram" text="Instagram" />
                        <Socials href="https://facebook.com/michuyim" icon="fa fa-facebook" text="Facebook" />
                        <Socials href="https://twitter.com/michuyim" icon="fa fa-twitter" text="Twitter" />
                    </ul>
                </div>
            </div>
       </div>
    )
}