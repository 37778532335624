import "./footer.css";

export const Footer = () => {

    const SocialLink = ({href, txt, icon}) => {
        return (
            <li className="social-link">
                <a href={href} target="_blank" rel="noopener noreferrer" className="text-decoration-none footer-button" aria-label={txt}>
                    <i className={`fa ${icon}`}></i>
                </a>
            </li>
        )
    }

    const FtBtn = ({href, text, src}) => {
        return (
            <a className="footer-button text-decoration-none" href={href}>
                <img src={src} alt="icon" />
                {text}
            </a>
        )
    }
    return (
        <div className="footer-container">
            <div className="footer">
                <div className="footer-column">
                    <a href="https://michuyim.com" className="footer-logo text-center text-decoration-none fw-bold fs-6">
                    michuyim_
                    </a>
                    <div className="text-center socials">
                        <ul>
                            <SocialLink href="https://facebook.com/michuyim" txt="Facebook" icon="fa-facebook" />
                            <SocialLink href="https://instagram.com/michuyim" txt="Instagram" icon="fa-instagram" />
                            <SocialLink href="https://github.com/michuyim" txt="Github" icon="fa-github" />
                        </ul>
                    </div>
                    <span className="copyright text-center">
                        <i className="fa fa-copyright"></i> michuyim 2024
                    </span>

                </div>
                <div className="footer-column ">
                    <FtBtn href="/" text="Home" src="/public/img/smile.svg" />
                    <FtBtn href="/photos" text="Photos" src="/public/img/camera.svg" />
                </div>
                <div className="footer-column">
                    <FtBtn href="mailto:contact@michuyim.com" text="Email" src="/public/img/email.svg" />
                    <FtBtn href="/projects" text="Projects" src="/public/img/coding.svg" />
                </div>
            </div>
        </div>
    )
}