import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navbar } from "./components/Navbar/navbar";
import Home from "./pages/HomePage";
import About from "./pages/AboutPage";
import Project from "./pages/ProjectPage";
import Photos from "./pages/PhotosPage";
import "./App.css";
import { Footer } from "./components/Footer/footer";

function App() {
  return (
    <Router>
      <div className="container">
        <Navbar />
        <div className="container mt-3">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Project />} />
            <Route path="/photos" element={<Photos />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  )
}

export default App;