import "./about.css";


export const AboutMe = () => {
    const Terminal = ({ green, retrn }) => {
        return (
        <div className="statement">
            <p className="input">
                michuyim.<span className="green">{green}</span>
            </p>
            <p className="return">
                {retrn}
            </p>
        </div>
        )
    }
    
    const ExpCard = ({ dgr, ins }) => {
        return (
           <div className="education-experience-card">
            <div className="card-info">
                <h4 className="green">{dgr}</h4>
                <p>{ins}</p>
            </div>
           </div>
        )
    };

    const WrkExp = ({ grn, dte, hrf, cmp }) => {
        return (
            <div className="education-experience-card">
                <div className="card-info">
                    <h4 className="green">{grn}</h4>
                    <p className="date">{dte}</p>
                    <p><a href={hrf}>{cmp}</a></p>
                </div>
            </div>
        )
    };

    const Soos = ({href, css, text}) => {
        return (
            <a href={href} className="social-media-item">
                <i className={`fa ${css}`}></i>
                {text}
            </a>
        )
    };

    
    return (
        <div className="main-container section">
            <div className="section" id="profile">
                <div className="page-title">
                    <h2>michuyim.<span className="pink">profile</span></h2>
                </div>

                <p className="section-description">
                    Discovering a whole new world, one component at a time.
                </p>
                <div className="profile-container">
                  {/*  <div className="me-icon-container">
                        <img className="me-icon img-thumbnail" src="/img/art-one.png" alt="Icon of me" title="Icon of me" />
                    </div> */}

                    <div className="terminal-container">
                        <div className="terminal-header">
                            <div id="terminal-title">
                                michuyim.com
                            </div>

                            <div className="right-side-buttons">
                                <i className="fa-solid fa-window-minimize"></i>
                                <i className="fa-solid fa-window-restore"></i>
                                <i className="fa-solid fa-window-close"></i>
                            </div>
                        </div>

                        <div className="terminal-window">
                            <Terminal green="pronouns" retrn="he/him" />
                            <Terminal green="location" retrn="rivers, nigeria" />
                            <Terminal green="major" retrn="mechanical engineering" />
                            <Terminal green="institution" retrn="rivers state university" />

                            <div className="statement">
                                <p className="input bottom"><span></span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" id="educational-experience">
                <div id="education" className="education-experience-container">
                    <h3>Education</h3>
                    <ExpCard dgr="B.Sc in Mechanical Engineering(2025)" ins="Rivers State University" />
                </div>

                <div id="experience" className="education-experience-container mb-3">
                    <h3>Experience</h3>
                    <div className="education-experience-card">
                        <div className="card-info">
                            <h4><em>nil</em></h4>
                        </div>
                    </div>
                </div>
            </div>

           
        </div>
    )
}